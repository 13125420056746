import axios from 'axios';
import { JournalEntryPostingsAPIsConstants } from '@/views/modules/accounting/store/enums/JournalEntryPostingsEnums';

const JournalEntryPostingsModule = {
   namespaced: true,
   loading: false,
   crudLoading: false,
   state: {
      itemEditable: {},
      settings: {},
   },
   mutations: {
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
   },
   actions: {
      cancelPosting({ commit }, data) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${JournalEntryPostingsAPIsConstants.API_PATH}/cancel-posting`, data.values)
               .then((res) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(res);
               })
               .catch((error) => {
                  // console.log(error);
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },
      createPosting({ commit }, data) {
         console.log(data);
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${data?.apiPath}`, data.values)
               .then((res) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(res);
               })
               .catch((error) => {
                  // console.log(error);
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },
      unpostEntry(_, { type, id }) {
         return axios.post(`/accounting/journal-entry-postings/unposting/${type}/${id}`);
      },
   },
   getters: {},
};
export default JournalEntryPostingsModule;
