import html2canvas from 'html2canvas';

function addStyles(win, styles) {
   styles.forEach((style) => {
      let link = win.document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('type', 'text/css');
      link.setAttribute('href', style);
      win.document.getElementsByTagName('head')[0].appendChild(link);
   });
}

function replaceCharts(win) {
   document.querySelectorAll('canvas').forEach((chart) => {
      const chartToReplace = win.document.querySelector(`canvas#${chart.id}`);
      if (!chartToReplace) return;
      const parent = chartToReplace.parentElement;

      const img = win.document.createElement('img');
      img.src = chart.toDataURL();
      img.style.width = '100%';

      parent.appendChild(img);
      parent.style.height = 'auto';
      parent.style.minHeight = 'auto';
      chartToReplace.remove();
   });

   document.querySelectorAll('div.apexcharts-canvas').forEach((chart) => {
      const chartToReplace = win.document.querySelector(`div#${chart.id}`);
      if (!chartToReplace) return;
      const parent = chartToReplace.parentElement;

      const img = win.document.createElement('img');
      html2canvas(chart).then((canvas) => (img.src = canvas.toDataURL()));
      img.style.width = '100%';

      parent.appendChild(img);
      parent.style.height = 'auto';
      parent.style.minHeight = 'auto';
      chartToReplace.remove();
   });

   win.document.querySelectorAll('.vue-apexcharts').forEach((container) => {
      container.style.minHeight = 'auto';
   });
}

function getPageHeight(printOptions) {
   return printOptions.landscape ? 780 : 1108;
}

window.mobileAndTabletCheck = function () {
   let check = false;
   (function (a) {
      if (
         /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a,
         ) ||
         /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
         )
      )
         check = true;
   })(navigator.userAgent || navigator.vendor || window.opera);
   return check;
};

const isMobile = window.mobileAndTabletCheck()

const VueHtmlToPaper = {
   install(app, options = {}) {
      app.config.globalProperties.$htmlToPaper = (el, printOptions = {}) => {
         let defaultName = '_blank',
            defaultSpecs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
            defaultReplace = true,
            defaultStyles = [];
         let {
            name = defaultName,
            specs = defaultSpecs,
            replace = defaultReplace,
            styles = defaultStyles,
         } = options;
         const { landscape, disablePageNumber, autoSize } = printOptions;

         specs = specs.length ? specs.join(',') : '';

         const element = window.document.getElementById(el);

         if (!element) {
            alert(`Element to print #${el} not found!`);
            return;
         }

         const url = '';
         const win = window.open(url, name, specs, replace);
         const lang = document.documentElement.getAttribute('lang');
         const bodyClasses = document.body.classList.value;
         const pageLayoutClasses = document.getElementById('pageLayout').classList.value;
         const pageHeight = getPageHeight(printOptions);
         const userType = document.documentElement.getAttribute('user-type');

         win.document.write(`
          <html lang="${lang}">
            <head>
            <style>
            /******* begin::loader *******/
            .overlay{
                position: relative;
            }
            #overlayLayer{
            display: none;
            }
            .overlay .overlay-layer {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex !important;
                justify-content: center;
                align-items: center;
                background-color: rgba(0, 0, 0, 0.05);
                transition: all 0.3s ease;
                opacity: 1 !important;
                z-index: 1;
                background-color: white !important;
            }
            .spinner-border {
                display: inline-block;
                width: 2rem;
                height: 2rem;
                vertical-align: -0.125em;
                border: 0.185em solid currentColor;
                border-right-color: transparent;
                border-radius: 50%;
                -webkit-animation: 0.65s linear infinite spinner-border;
                animation: 0.65s linear infinite spinner-border;
            }
            
            /******* end::loader *******/
           
            .report-page{
                width: 100%;
                position: relative;
               }
               
            * {
              -webkit-print-color-adjust: exact !important;   /* Chrome, Safari, Edge */
              color-adjust: exact !important;                /* Firefox 48 – 96 */
              print-color-adjust: exact !important;   
             -webkit-print-color-adjust: exact; 
            }
            table.dataTable thead tr *:not(.print-bg) {
                background-color: inherit !important;
            }
            
            table.dataTable tbody tr *:not(.print-bg) {
                background-color: inherit !important;
            }
            body {
               background: white !important;
            }
             @media print {
                 body {
                      /*margin-top: 40mm !important; */
                 }
                  html, body {
                    margin:0;
                    padding:0;
                  }
                  br.page-break {
                    display:block;
                    page-break-before:always;
                  }
                  table {
                    page-break-after:auto;
                  }
                  tr {
                    page-break-inside:avoid;
                    page-break-after:auto
                  }
                  td {
                    page-break-inside:avoid;
                    page-break-after:auto
                  }
                  thead {
                    display:table-header-group;
                    /*display: table-row-group;*/
                  }
                  tfoot {
                    display:table-footer-group
                  }
                  img {
                    display:block;
                  } 
                  .img-wrapper{
                   display: flex;
                   justify-content: center;
                  }
               }
               .no-break {
                  page-break-inside: avoid;
                  break-inside: avoid;
                  position: relative;
               }
               .table-header div {
                  outline: 1px solid black;
               }
               .table-row {
                  border-bottom: 1px solid black;
                  padding-top: 1px;
               }
               html, body {
                  height: auto !important;
               }
               .watermark {
                  transform: translate(50%) rotateZ(-45deg);
                  position: absolute;
                  top: 50%;
                  right: 50%;
                  filter: opacity(0.1);
                  display: none;
               }
               
               .watermark.demo{
                  display: block;
               }
               
            </style>
              <title>${window.document.title}</title>
              <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css">
              <link rel="stylesheet" type="text/css" href="/assets/css/app.css">
              <link rel="stylesheet" type="text/css" href="/assets/css/chunk-vendors.css">
            </head>
            <body id="kt_body" class="${bodyClasses} status_printing overlay">
                <div id="app" class="d-flex flex-column flex-root">
                    <div id="pageLayout" class="${pageLayoutClasses}">
                        <div style="width: 99% !important;">
                           <table style="width: 100%">
                              <tbody><tr><td><div>${element.innerHTML}</div></td></tr></tbody>
                              <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
                           </table>
                        </div>
                        
                        <div class="watermark ${userType}">
                          <img class="w-200px" src="media\\logos\\footer-logo1.png" alt="" />
                          <h1 class="text-center">${
                             lang == 'en' ? 'Free Trial' : 'تجربة مجانية'
                          }</h1>
                      </div>
                    </div>
                </div>
                <div id="overlayLayer" class="overlay-layer">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </body>
            <script>                
                document.addEventListener("DOMContentLoaded", function(){
                     if (${disablePageNumber ? 'true' : 'false'}) return;
                     setTimeout(() => {
                        var totalPages = Math.ceil(document.getElementById('app').scrollHeight / ${pageHeight});
   
                        for (var i = 1; i <= totalPages; i++) {
                           var pageNumberDiv = document.createElement("div");
                           var pageNumber = document.createTextNode(i + "/" + totalPages);
                           pageNumberDiv.style.position = "absolute";
                           pageNumberDiv.style.top = "calc((" + i + " * ${pageHeight}px) - 18px)";
                           pageNumberDiv.style.height = "10px";
                           pageNumberDiv.style.zIndex = 1;
                           pageNumberDiv.appendChild(pageNumber);
                           document.documentElement.append(pageNumberDiv);
                           pageNumberDiv.style.left = "50%";
                        }
                     }, 1000);
                });


                window.addEventListener("load", function() {
                    var element = document.getElementById("overlayLayer");
                    element.classList.remove("overlay-layer");
                    setTimeout(() => {
                        window.print();
                        ${process.env.VUE_APP_REPORTS_DEBUG || isMobile ? '' : 'window.close();'}
                    }, 2000);
                });
            </script>
          </html>
        `);

         addStyles(win, [
            '/css/print.css',
            autoSize ? '/css/auto.css' : landscape ? '/css/landscape.css' : '/css/A4.css',
         ]);

         setTimeout(() => {
            replaceCharts(win);
            win.document.close();
            win.focus();
         }, 500);

         return true;
      };
   },
};

export default VueHtmlToPaper;
