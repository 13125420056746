const AccountingMenuConfig = [
   {
      heading: 'settings',
      route: '/settings',
      module_id: 3,
      pages: [
         {
            heading: 'Accounting settings',
            route: '/accounting-management/settings-accounting-mangment',
         },
         {
            heading: 'Cost Center',
            route: '/accounting-management/cost-center',
            permission: 'cost_centers',
         },
         {
            heading: 'Cost Center Groups',
            route: '/accounting-management/cost-center-groups',
            permission: 'cost_center_groups',
         },
         {
            heading: 'projects',
            route: '/accounting-management/projects',
            permission: 'projects',
         },
         {
            heading: 'project groups',
            route: '/accounting-management/project-groups',
            permission: 'project_groups',
         },
         {
            heading: 'Chart Of Account',
            route: '/accounting-management/journal-entries-list',
            permission: 'account',
         },
         {
            heading: 'account groups',
            route: '/accounting-management/account-groups',
            permission: 'account_groups',
         },
         {
            heading: 'Periodical Journal Templates',
            route: '/accounting-management/journal-entry-template',
         },
      ],
   },
   {
      heading: 'Daily Transaction',
      route: '#',
      module_id: 3,
      pages: [
         {
            heading: 'Journal Entry',
            route: '/accounting-management/journal-entry',
         },
         {
            heading: 'Receipt voucher',
            route: '/accounting-management/receipt-voucher',
         },
         {
            heading: '_Payment Voucher',
            route: '/accounting-management/payment-voucher',
         },
         {
            heading: 'approval Receipt',
            route: '/accounting-management/accounting-approval-list',
         },
         {
            heading: 'unapproval Receipt',
            route: '/accounting-management/accounting-unapproval-list',
         },
         {
            heading: 'Journal Posting',
            route: '/accounting-management/journal-posting',
         },
         {
            heading: 'Cancel Posting',
            route: '/accounting-management/cancel-journal-posting',
         },
      ],
   },
   {
      heading: 'Reports',
      route: '/reports',
      pages: [
         {
            sectionTitle: 'General Journal',
            sub: [
               {
                  heading: '_total',
                  route: '/accounting-management/general-journal-report',
                  permission: 'accounting_reports',
               },
               {
                  heading: 'Detailed',
                  route: '/accounting-management/detailed-journal-report',
                  permission: 'accounting_reports',
               },
            ],
         },

         {
            sectionTitle: 'Ledger',
            sub: [
               {
                  heading: '_total',
                  route: '/accounting-management/ledger-report',
                  permission: 'accounting_reports',
               },
               {
                  heading: 'Detailed',
                  route: '/accounting-management/detailed-ledger-report',
                  permission: 'accounting_reports',
               },
            ],
         },
         {
            heading: 'Subsidiary Ledger Account',
            route: '/accounting-management/subsidiary-ledger-account',
            permission: 'accounting_reports',
         },
         {
            heading: 'Receipts Within Period',
            route: '/accounting-management/receipts-within-period',
            permission: 'accounting_reports',
         },
         {
            heading: 'Payments Within Period',
            route: '/accounting-management/payments-within-period',
            permission: 'accounting_reports',
         },
         {
            sectionTitle: 'Cost Centers',
            sub: [
               {
                  heading: '_total',
                  route: '/accounting-management/cost-center-total-report',
                  permission: 'accounting_reports',
               },
               {
                  heading: 'Detailed',
                  route: '/accounting-management/cost-center-detailed-report',
                  permission: 'accounting_reports',
               },
               {
                  heading: 'Analyze',
                  route: '/accounting-management/cost-center-analyze-report',
                  permission: 'accounting_reports',
               },
               // {
               //    heading: 'Cost Center Trial Balance',
               //    route: '/accounting-management/cost-center-trial-balance',
               //    permission: 'accounting_reports',
               // },
            ],
         },

         {
            sectionTitle: 'Projects',
            sub: [
               {
                  heading: '_total',
                  route: '/accounting-management/projects-total-report',
                  permission: 'accounting_reports',
               },
               {
                  heading: 'Detailed',
                  route: '/accounting-management/projects-detailed-report',
                  permission: 'accounting_reports',
               },
               {
                  heading: 'Analyze',
                  route: '/accounting-management/projects-analyze-report',
                  permission: 'accounting_reports',
               },
               // {
               //    heading: 'Projects Trial Balance',
               //    route: '/accounting-management/projects-trial-balance',
               //    permission: 'accounting_reports',
               // },
            ],
         },
         {
            heading: 'Accounts Group',
            route: '/accounting-management/accounts-group-report',
            permission: 'accounting_reports',
         },
         {
            heading: 'Cost Center Groups',
            route: '/accounting-management/cost-center-groups-report',
            permission: 'accounting_reports',
         },
         {
            heading: 'projectsGroupReports',
            route: '/accounting-management/projects-group-reports',
            permission: 'accounting_reports',
         },

         {
            sectionTitle: 'Trial balance',
            sub: [
               {
                  heading: 'By balances',
                  route: '/accounting-management/trial-balance-report',
                  permission: 'accounting_reports',
               },
               {
                  heading: 'By totals',
                  route: '/accounting-management/trial-balance-by-total-report',
                  permission: 'accounting_reports',
               },
               {
                  heading: 'Analyze',
                  route: '/accounting-management/analyze-trial-balance-by-total-report',
                  permission: 'accounting_reports',
               },
            ],
         },

         {
            heading: 'Income Statement',
            route: '/accounting-management/income-statement-report',
            permission: 'accounting_reports',
         },
         {
            heading: 'Balance Sheet',
            route: '/accounting-management/budget-report',
            permission: 'accounting_reports',
         },
      ].filter(Boolean),
   },
];
export default AccountingMenuConfig;
