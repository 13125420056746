import { RouteRecordRaw } from 'vue-router';

const dailyMovementRouter: Array<RouteRecordRaw> = [
   //begin::receipt-voucher
   {
      path: 'receipt-voucher',
      name: 'accounting-receipt-voucher',
      meta: {
         permission: 'receipt_vouchers',
         title: 'Receipt voucher',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/receipt-voucher/List.vue'),
   },

   {
      path: 'receipt-voucher/:id/:view',
      name: 'accounting-receipt-voucher-view',
      props: true,
      meta: {
         title: 'Receipt voucher',
         permission: 'receipt_vouchers',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/receipt-voucher/Add.vue'),
   },
   {
      path: 'add-receipt-voucher',
      name: 'accounting-receipt-voucher-add',
      meta: {
         permission: 'receipt_vouchers',
         title: 'Receipt voucher',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/receipt-voucher/Add.vue'),
   },
   {
      path: 'update-receipt-voucher/:id',
      name: 'accounting-receipt-voucher-update',
      meta: {
         permission: 'receipt_vouchers',
         title: 'Receipt voucher',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/receipt-voucher/Add.vue'),
   },
   {
      path: 'receipt-voucher/duplicate/:duplicationId',
      name: 'accounting-duplicate-receipt-voucher',
      props: true,
      meta: {
         title: 'Receipt voucher',
         permission: 'receipt_vouchers',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/receipt-voucher/Add.vue'),
   },
   //end::receipt-voucher
   //begin::payment-voucher
   {
      path: 'payment-voucher',
      name: 'accounting-payment-voucher',
      meta: {
         permission: 'payment_vouchers',
         title: '_Payment Voucher',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/payment-voucher/List.vue'),
   },
   {
      path: 'accounting-management-payment-view-model-settings/:id',
      name: 'accounting-management-payment-view-model-settings',
      props: true,
      meta: {
         permission: '',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/payment-voucher/Add.vue'),
   },
   {
      path: 'add-payment-voucher',
      name: 'accounting-payment-voucher-add',
      meta: {
         permission: 'payment_vouchers',
         title: '_Payment Voucher',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/payment-voucher/Add.vue'),
   },
   {
      path: 'update-payment-voucher/:id',
      name: 'accounting-payment-voucher-update',
      meta: {
         permission: 'payment_vouchers',
         title: '_Payment Voucher',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/payment-voucher/Add.vue'),
   },
   {
      path: 'payment-voucher/:id/:view',
      name: 'accounting-payment-voucher-view',
      meta: {
         permission: 'payment_vouchers',
         title: '_Payment Voucher',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/payment-voucher/Add.vue'),
   },
   {
      path: 'payment-voucher/duplicate/:duplicationId',
      name: 'accounting-duplicate-payment-voucher',
      meta: {
         permission: 'payment_vouchers',
         title: '_Payment Voucher',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/payment-voucher/Add.vue'),
   },
   //end::payment-voucher

   //begin::journal-entry
   {
      path: 'journal-entry',
      name: 'accounting-journal-entry',
      meta: {
         permission: 'journal_entries',
         title: 'Journal Entry',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry/List.vue'),
   },
   {
      path: 'add-journal-entry',
      name: 'accounting-journal-entry-add',
      meta: {
         permission: 'journal_entries',
         title: 'Journal Entry',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry/Add.vue'),
   },
   {
      path: 'journal-entry/duplicate/:duplicationId',
      name: 'accounting-duplicate-journal-entry',
      meta: {
         permission: 'journal_entries',
         title: 'Journal Entry',
         type: 'write',
      },
      props: true,
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry/Add.vue'),
   },
   {
      path: 'add-journal-entry/:isReverse/:journalId',
      name: 'accounting-journal-entry-reverse-add',
      meta: {
         permission: 'journal_entries',
         title: 'Journal Entry',
         type: 'write',
      },
      props: true,
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry/Add.vue'),
   },
   {
      path: 'journal-entry/add/:fromTemplate/:templateId',
      name: 'accounting-management-accounting-journal-entry-from-template-add',
      meta: {
         permission: 'journal_entries',
         title: 'Journal Entry',
         type: 'write',
      },
      props: true,
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry/Add.vue'),
   },
   {
      path: 'update-journal-entry/:id',
      name: 'accounting-journal-entry-update',
      meta: {
         permission: 'journal_entries',
         title: 'Journal Entry',
         type: 'write',
      },
      props: true,
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry/Add.vue'),
   },
   {
      path: 'update-journal-entry/:id/:view',
      name: 'accounting-journal-entry-view',
      meta: {
         permission: 'journal_entries',
         title: 'Journal Entry',
      },
      props: true,
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry/Add.vue'),
   },
   //end::journal-entry

   //begin::journal-entry-template
   {
      path: 'journal-entry-template',
      name: 'accounting-management-accounting-journal-entry-template',
      meta: {
         permission: 'journal_entry_templates',
         title: 'Periodical Journal Templates',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry-template/List.vue'),
   },
   {
      path: 'add-journal-entry-template',
      name: 'accounting-management-accounting-journal-entry-template-add',
      meta: {
         permission: 'journal_entry_templates',
         title: 'Periodical Journal Templates',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry-template/Add.vue'),
   },
   {
      path: 'update-journal-entry-template/:id',
      name: 'accounting-management-accounting-journal-entry-template-update',
      meta: {
         permission: 'journal_entry_templates',
         title: 'Periodical Journal Templates',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry-template/Add.vue'),
   },
   {
      path: 'update-journal-entry-template/:id/:view',
      name: 'accounting-management-accounting-journal-entry-template-view',
      meta: {
         permission: 'journal_entry_templates',
         title: 'Periodical Journal Templates',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-entry-template/Add.vue'),
   },
   {
      path: 'journal-posting',
      name: 'journal-posting',
      meta: {
         // permission: 'journal_posting',
         title: 'Journal Posting',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-posting/base.vue'),
   },
   {
      path: 'cancel-journal-posting',
      name: 'cancel-journal-posting',
      meta: {
         // permission: 'cancel_journal_posting',
         title: 'Cancel Posting',
      },
      component: () =>
         import('@/views/modules/accounting/views/daily-movements/journal-posting/cancel.vue'),
   },
   //end::journal-entry-template
];

export default dailyMovementRouter;
