const path = 'JournalEntryPostingsModule';
const apiPath = '/accounting/journal-entry-postings';

const JournalEntryPostingsModelActions = {
   // action types
   CANCEL_ITEM: `${path}/cancelPosting`,
   MAKE_ITEM: `${path}/createPosting`,
   UNPOST_ENTRY: `${path}/unpostEntry`,
};

const JournalEntryPostingsAPIsConstants = {
   // api's
   API_PATH: `${apiPath}`,
   ACCOUNTING_MAKE_ITEM_API_PATH: `${apiPath}/create-posting`,
   TRANSPORT_MAKE_ITEM_API_PATH: `transport/transport-accounting/migrate-to-accounting`,
   CUSTOMS_MAKE_ITEM_API_PATH: `customs/customs-accounting/migrate-to-accounting`,
   ASSET_MAKE_ITEM_API_PATH: `asset/asset-accounting/migrate-to-accounting`,
};

enum JournalEntryPostingsTypes {
   PurchaseInvoice = 'PurchaseInvoice',
   PurchaseFinancialTransaction = 'PurchaseFinancialTransaction',
   SalesInvoice = 'SalesInvoice',
   SalesFinancialTransaction = 'SalesFinancialTransaction',
   WarehouseAddNotice = 'WarehouseAddNotice',
   WarehouseDismissalNotice = 'WarehouseDismissalNotice',
   WarehouseExpense = 'WarehouseExpense',
   WarehouseReturnAddNotice = 'WarehouseReturnAddNotice',
   WarehouseReturnDismissalNotice = 'WarehouseReturnDismissalNotice',
   WarehouseTransferNotice = 'WarehouseTransferNotice',
   TransportPurchaseFinancialTransaction = 'TransportPurchaseFinancialTransaction',
   TransportSalesFinancialTransaction = 'TransportSalesFinancialTransaction',
   CustomsInvoice = 'CustomsInvoice',
   CustomsInvoiceFinancialTransaction = 'CustomsInvoiceFinancialTransaction',
   TransportPurchaseInvoice = 'TransportPurchaseInvoice',
   TransportSalesInvoice = 'TransportSalesInvoice',
   PaymentVoucher = 'PaymentVoucher',
   ReceiptVoucher = 'ReceiptVoucher',
   CustomsReceiptVoucher = 'CustomsReceiptVoucher',
   CustomsPaymentVoucher = 'CustomsPaymentVoucher',
}

export {
   JournalEntryPostingsAPIsConstants,
   JournalEntryPostingsModelActions,
   JournalEntryPostingsTypes,
};
